.contact-us .time-range {
  display: inline-block;
  float: right;
}
.contact-us .inner {
  box-shadow: 0px 0px 10px #00000024;
  border-radius: 5px;
  overflow: hidden;
}
.contact-us .contact-us-left {
  width: 100%;
  height: 100%;
}
.contact-us .contact-us-form {
  padding: 50px 40px;
}
.contact-us .contact-us-form h2 {
  position: relative;
  font-size: 32px;
  color: #333;
  font-weight: 600;
  line-height: 27px;
  text-transform: capitalize;
  margin-bottom: 12px;
  padding-bottom: 20px;
  text-align: left;
}
.contact-us .contact-us-form h2:before {
  position: absolute;
  content: '';
  left: 0;
  bottom: 0;
  height: 2px;
  width: 50px;
  background: #536fb3;
}
.contact-us .contact-us-form p {
  font-size: 14px;
  color: #333;
  font-weight: 400;
  text-align: left;
  margin-bottom: 50px;
}
.contact-us .form {
  margin-top: 30px;
}
.contact-us .form .form-group {
  margin-bottom: 22px;
}
.contact-us .form .form-group input {
  width: 100%;
  height: 50px;
  border: 1px solid #eee;
  text-transform: none;
  padding: 0px 18px;
  color: #555;
  font-size: 14px;
  font-weight: 400;
  border-radius: 4px;
}
.contact-us .form .form-group .error {
  width: 100%;
  color: red;
  font-size: 14px;
  font-weight: 400;
}
.contact-us .form .form-group textarea {
  width: 100%;
  height: 100px;
  border: 1px solid #eee;
  text-transform: none;
  padding: 18px;
  color: #555;
  font-size: 14px;
  font-weight: 400;
  border-radius: 4px;
}
.contact-us .form .form-group.login-btn {
  margin: 0;
}
.contact-us .form button {
  border: none;
}
.contact-us .form .btn {
  display: inline-block;
  margin-right: 10px;
  color: #fff;
  line-height: 20px;
  width: 100%;
}
.contact-us .form .btn:hover {
  background: #536fb3;
  color: #fff;
}
.contact-us .contact-us-form .checkbox {
  text-align: left;
  margin: 0;
  margin-top: 20px;
  display: inline-block;
}
.contact-us .contact-us-form .checkbox label {
  font-size: 14px;
  font-weight: 400;
  color: #333;
  position: relative;
  padding-left: 20px;
}
.contact-us .contact-us-form .checkbox label:hover {
  cursor: pointer;
}
.contact-us .contact-us-form .checkbox label input {
  display: none;
}
.contact-us .contact-us-form .checkbox label::before {
  position: absolute;
  content: '';
  left: 0;
  top: 5px;
  width: 15px;
  height: 15px;
  border: 1px solid #536fb3;
  border-radius: 100%;
}
.contact-us .contact-us-form .checkbox label::after {
  position: relative;
  content: '';
  width: 7px;
  height: 7px;
  left: -16px;
  top: -15px;
  opacity: 0;
  visibility: hidden;
  transform: scale(0);
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  display: block;
  font-size: 9px;
  background: #536fb3;
  border-radius: 100%;
}
.contact-us .contact-us-form .checkbox label.checked::after {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}
.contact-us .contact-info {
  margin-top: 50px;
}
.contact-us .single-info {
  background: #536fb3;
  padding: 40px 60px;
  height: 150px;
  border-radius: 10px;
  position: relative;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.contact-us .single-info:before {
  position: absolute;
  z-index: -1;
  content: '';
  bottom: -10px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 80%;
  height: 90%;
  background: #536fb3;
  opacity: 0;
  filter: blur(10px);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.contact-us .single-info:hover:before {
  opacity: 0.8;
}
.contact-us .single-info:hover {
  transform: translateY(-5px);
}
.contact-us .single-info i {
  font-size: 42px;
  color: #fff;
  position: absolute;
  left: 40px;
}
.contact-us .single-info .content {
  margin-left: 45px;
}
.contact-us .single-info .content h3 {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
}
.contact-us .single-info .content p {
  color: #fff;
  margin-top: 5px;
}
/* Google Map */
.contact-us #myMap {
  height: 100%;
  width: 100%;
}
